import React from "react"
import { graphql } from "gatsby"
import GuidesIndex from '../templates/guidesIndex'

export default function Blog({ data, location }) {
  return (<GuidesIndex data={data.allWordpressWpGuide} location={location} />)
}


export const pageQuery = graphql`
  query {
    allWordpressWpGuide {
      edges {
        node {
          title
          slug
          excerpt
        }
      }
    }
    file(relativePath: { eq: "images/fallbackImage.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920){
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`