import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Grid, Button } from "@material-ui/core"
import { css } from '@emotion/core'
import PageHeader from '../components/Blog/pageHeader'
import Breadcrumbs from '../components/MainComponents/Breadcrumbs'

const BlogCategoryIndex = ({ data, title, location }) => {
  return (
    <Layout
      PageHeader={() => <PageHeader pageTitle="Guides" />}
    >
      <SEO 
        title="Guides"
        url={location.pathname}
        description={'Green Finance Guides are here to help you with a range of topics that include IFISA, online investment platforms and green technology'}
      />
      <Breadcrumbs url={location.pathname} />
      <Grid
        spacing={4}
        container
      >
        {data.edges.map(({ node }, index) => {
          return (
            <Grid sm={6}>
              <div
                css={css`
                  text-align: center;
                  margin: 0 auto;
                  margin-bottom: 20px;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  justify-content: space-between;
                `}
              >
                <div>
                  <h1>{node.title}</h1>
                  <div
                    css={css`
                      max-width: 450px;
                      margin: 0 auto;
                      p {
                        margin-bottom: 14px;
                      }
                    `} 
                    dangerouslySetInnerHTML={{__html : node.excerpt}}/>
                </div>
                <div>
                  <Link to={'/guides/' + node.slug}>
                    <Button variant="outlined" css={theme => 
                      css`
                        background-color: #679d38 !important;
                        color: white !important;
                        border: 1px solid white !important;`
                    }>Read More</Button>
                  </Link>
                  <div 
                    css={theme => css`
                      width: 100px;
                      background-color: ${theme.colors.lgrey};
                      height: 1px;
                      margin: 8px auto 0;
                    `}
                  />
                </div>
              </div>
            </Grid>
          )
        })}
        </ Grid>
    </Layout>
  )
}

export default BlogCategoryIndex